// src/analytics.ts
import axios from '@/plugin/my_axios'

export interface TrackEventData {
  [key: string]: never
}

export function trackEvent (event: string, data: TrackEventData): void {
  axios.post('/record/report', {
    event,
    data
  }).then(resp => {
    console.log('Event tracked:', event, data, resp.data.code)
  }).catch(error => {
    console.error('Error tracking event:', error)
  })
}
