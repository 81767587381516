import axios from 'axios'
import router from '../router'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

// 请求拦截器
axiosInstance.interceptors.request.use(config => {
  const token = window.localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = token
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截器
axiosInstance.interceptors.response.use(async response => {
  const data = response.data
  if (data.code === -101) {
    await router.push('/login')
  }
  return response
}, error => {
  return Promise.reject(error)
})

export default axiosInstance
