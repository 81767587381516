import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import FindView from '@/views/FindView.vue'
import CollectFace from '@/views/CollectFace.vue'
import UserLogin from '@/views/UserLogin.vue'
import IndexView from '@/views/IndexView.vue'
import LawTerms from '@/views/LawTerms.vue'
import AccountSecurity from '@/views/rare/AccountSecurity.vue'
import AppSettings from '@/views/rare/AppSettings.vue'
import H5VideoPlay from '@/components/H5VideoPlay.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: IndexView,
    children: [
      {
        path: '',
        name: 'default',
        redirect: 'home'
      },
      {
        path: '/home',
        name: 'home',
        component: HomeView
      },
      {
        path: '/find',
        name: 'find',
        component: FindView
      },
      {
        path: '/about',
        name: 'about',
        component: AboutView
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    children: [
      {
        path: 'login',
        name: 'login',
        component: UserLogin
      }
    ]
  },
  {
    path: '/terms',
    name: 'LawTerms',
    component: LawTerms
  },
  {
    path: '/account-security',
    name: 'AccountSecurity',
    component: AccountSecurity
  },
  {
    path: '/app-settings',
    name: 'AppSettings',
    component: AppSettings
  },
  {
    path: '/h5-play-video',
    name: 'VideoPlayH5',
    component: H5VideoPlay
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/user/login' || to.path === '/user/register' || to.path === '/terms') return next()
  // 获取token
  const token = window.localStorage.getItem('token')
  if (!token) {
    // 跳转到登录页
    await router.push('/user/login')
  }
  next()
})

export default router
