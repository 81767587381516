import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import analyticsPlugin from '@/plugin/analytics_plugin'
import { showNotify, showToast } from '@/plugin/nutui'

const app = createApp(App)

app.use(analyticsPlugin)
app.use(router)
app.use(showNotify)
app.use(showToast)

app.mount('#app')
